import { Link } from "@skf-internal/ui-components-react";

const Help = () => {
  return (
    <Link
      className="needhelp"
      as="a"
      href="https://skfprod.service-now.com/wp?id=skf_web_portal_producer&sys_id=9e8cb050db07a810774149ee3b96196d"
    >
      Need help?
    </Link>
  );
};

export default Help;
