export interface IUserHeaderProps {
  title : string ;
  subContent: any
}

const SubHeader = (props: IUserHeaderProps) => {
  const { title = "" , subContent="" } = props;

  return (
    <div className="userHeader-container">
      <div    className="userheader">
        <span className="h1">{title}</span>
        <div> {subContent}</div>
      </div>
    </div>
  );
};

export default SubHeader;
