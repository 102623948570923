import {
  Link,
  Icon,
  IconName,
  
} from "@skf-internal/ui-components-react";

export interface IFunctionCardProps {
   icon: IconName;
  heading: string;
  content?: any;
  moretext: string;
  morelink: any;
  secondLinkText?: string;
  secondLink?: any;
}

const HorizontalCard = (props: IFunctionCardProps) => {
  const { icon, heading, content, moretext, morelink , secondLinkText , secondLink } =
    props;

     const className = content === undefined ? 'application-card-section noContent' : 'application-card-section withcontent';
   
  return (
    <article className="application-card">
      <figcaption className="application-card-header">
        <Icon feIcon={icon} className="icon cardIconColor" feSize="md" />
        <header style={{ marginLeft: "2rem" }}>
          {" "}
          <h3>{heading}</h3>
        </header>
      </figcaption>
      <section className={className}>
      {!!content  &&  <summary className="application-card-details" >{content}</summary>}  
      {!!secondLinkText && <summary  className="application-card-button">
          <Link
             as="a"
            feIcon={{
              feIcon: "arrowRight",
              position: "left",
            }}
            href="#"
            onClick={() => secondLink()}
            className="link"
          >
            {secondLinkText}
          </Link>
        </summary>}  
        <summary className="application-card-button">
          <Link
          style={{width : !!secondLinkText ? '10rem': 'auto'}}
            as="a"
            feIcon={{
              feIcon: "arrowRight",
              position: "left",
            }}
            href="#"
            onClick={() => morelink()}
            className="link"
          >
            {moretext}
          </Link>
        </summary>
      </section>
    </article>
  );
};

export default HorizontalCard;
