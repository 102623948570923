import { useMsal } from "@azure/msal-react";
import "./App.css";
import {  EventType } from "@azure/msal-browser";
import { useEffect } from "react";
import PageLayout from "./features/components/PageLayout";
import { Route,  Routes } from "react-router-dom";
import UserProfile from "./features/pages/UserProfile";
import SecurityInfo from "./features/pages/SecurityInfo";
import PrivacyInfo from "./features/pages/PrivacyInfo";
import { policies } from "./policies";


function App() {
  const { instance, accounts } = useMsal();
  
  if (process.env.REACT_APP_ENVIRONMENT != "Production") {
    document.title = process.env.REACT_APP_ENVIRONMENT + " - My SKF account";
  } else {
    document.title = "My SKF account";
  }

  useEffect(() => {
    const callbackId = instance.addEventCallback((event:any) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        if (
          event.payload.idTokenClaims["acr"]?.toString().toUpperCase() === policies.names.editProfile?.toUpperCase()
        ) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = instance
            .getAllAccounts()
            .find(
              (account) =>
                account.idTokenClaims != null && 
                account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                account.idTokenClaims["acr"]?.toString().toUpperCase() === policies.names.signUpSignIn?.toUpperCase()
            );

          let signUpSignInFlowRequest = {
            authority: policies.authorities.signUpSignIn.authority,
            account: originalSignInAccount
          };
        

          // silently login again with the signUpSignIn policy
          let x = instance.ssoSilent(signUpSignInFlowRequest);
        }

      }

    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, [instance]);
  return (
    <>
      <PageLayout>
        <Routes>
          <Route path="/" element={<UserProfile />} />
          <Route path="/securityinfo" element={<SecurityInfo />} />
          <Route path="/privacyinfo" element={<PrivacyInfo />} />
        </Routes>
      </PageLayout>
    </>
  );
}

export default App;
