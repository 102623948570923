import { Header } from "@skf-internal/ui-components-react";
import User from "./User";
import Help from "./Help";
import Footer from "./Footer";

export interface IPageLayoutProps {
  children: any;
}

export default function PageLayout(props: IPageLayoutProps): JSX.Element {
  const { children } = props;
  return (
    <>
      <Header
        feIntro={{
          baseUrl: "/",
          name: `My SKF account`,
        }}
        className="header"
      />
      <>
        <User auth={null} />
        {children}
        <Help />
        <Footer />
      </>
    </>
  );
}
