import { useMsal } from "@azure/msal-react";
import { Link } from "@skf-internal/ui-components-react";

const LogoutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance
      .logoutRedirect()
      .then(() => {
        })
      .catch((error) => {
        console.error("Logout error", error);
      });
  };

  return (
    <Link
      as="a"
      href="#"
      onClick={() => {
        handleLogout();
      }}
    >
      Log out
    </Link>
  );
};

export default LogoutButton;
