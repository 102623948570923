import { loginRequest, msalConfig, silentRequest } from "../../authConfig";

import { PublicClientApplication } from "@azure/msal-browser";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export function fetchAccessToken() {
  const msalInstance = new PublicClientApplication(msalConfig);
  try {
    return new Promise((resolve) => {
      msalInstance.acquireTokenSilent(silentRequest).then((response) => {
        resolve(response.accessToken);
      });
    });
  } catch (error) {
    console.error(error);
    if (error instanceof InteractionRequiredAuthError) {
      return msalInstance
        .acquireTokenPopup(loginRequest)
        .then((resp) => {
          return resp.accessToken;
        })
        .catch((err) => {
          console.error(err);
          return null;
        });
    } else {
      console.error(error);
    }
  }
  return null;
}
