
const Footer = () => {

  const chinaCDN = document.cookie.includes("china_cdn");  

  return chinaCDN ? <><div className="icp">
      <a target="_blank" href="https://beian.miit.gov.cn"><span> 沪ICP备09046910号</span></a>
    </div></>: null;
};

export default Footer;
