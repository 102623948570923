import  { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { EventType, AccountInfo } from "@azure/msal-browser";
import SubHeader from "../components/SubHeader";

import HorizontalCard from "../components/HorizontalCard";
import { policies } from "../../policies";
import { InteractionStatus } from "@azure/msal-browser";
import { useNavigate } from "react-router-dom";
import { SettingsCard } from "../components/SettingsCard";

const UserProfile = () => {
  const { instance, accounts, inProgress } = useMsal();

  //to be developed
  const language = "en";
  const navigate = useNavigate();

  const [user, setUser] = useState<any>();
  const [userName, setUserName] = useState<string>("");

  useEffect(() => {
    let storageUserStr = sessionStorage.getItem("user"); 
    if (storageUserStr != null){
      let storageUser =  JSON.parse(storageUserStr) as AccountInfo
      setUser(storageUser);
      setUserName(storageUser?.name??""); 
    } else if (accounts.length > 0) {
      setUser(accounts[0]);
      setUserName(accounts[0].name??"");
      sessionStorage.setItem("user", JSON.stringify(accounts[0])); 
    }


    const callbackId = instance.addEventCallback((event:any) => {
      if ( event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.payload.account) {
        setUser(event.payload.account);
        setUserName( event.payload.account.name);
        sessionStorage.setItem("user", JSON.stringify(event.payload.account)); 
      }
    });

    // Clean up the event listener on unmount
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [accounts, instance]);
 
  const securityContent = (
    <ul>
      <li>Change your password</li>
    </ul>
  );

  const privacyContent = (
    <ul>
      <li>Link to privacy page</li>
      <li>Marketing consent</li>
      <li>What data we collect and why</li>
    </ul>
  );

  const handleProfileEdit = () => {
    const editProfileRequest: any = policies.authorities.editProfile;

    if (language !== "en") {
      editProfileRequest.extraQueryParameters = { ui_locales: language };
    }
    if (inProgress === InteractionStatus.None) {
      instance.acquireTokenRedirect(editProfileRequest);
    }
  };

  const handleDelete = () => {
    const deleteUserRequest: any = policies.authorities.deleteUser;
    if (language !== "en") {
      deleteUserRequest.extraQueryParameters = { ui_locales: language };
    }
    if (inProgress === InteractionStatus.None) {
      instance.acquireTokenRedirect(deleteUserRequest);
    }
  };

  const handlePrivacy = () => {
    window.open(process.env.REACT_APP_POLICY_WEB, "_blank");
  };
  return (
    <>
      <SubHeader title={ `Hello, ${userName}`} subContent={"Welcome to your SKF account."} />
      <section className="application-card-container">
       
            <SettingsCard
              icon={"person"}
              heading="My Profile"
              morelink={handleDelete}
              moretext="Delete account"
              secondLinkText="Update your personal details"
              secondLink={handleProfileEdit}
              user={user}
            />
        {user?.idTokenClaims?.idp == null && 
          <HorizontalCard
            icon={"lock"}
            heading="Security"
            content={securityContent}
            morelink={() => navigate("/SecurityInfo")}
            moretext="Open"
          />
        }
          <HorizontalCard
            icon={"visibility"}
            heading="Privacy"
            content={privacyContent}
            morelink={() => navigate("/PrivacyInfo")}
            moretext="Open"
          />
     </section>
    </>
  );
};

export default UserProfile;
