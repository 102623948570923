/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const policies = {
  names: {
    signUpSignIn: process.env.REACT_APP_NAMES_SIGNUP_SIGNIN,
    editProfile: process.env.REACT_APP_NAMES_EDITPROFILE,
    deleteUser: process.env.REACT_APP_NAMES_DELETEUSER,
    changePassword: process.env.REACT_APP_NAMES_CHANGEPASSWORD,
    handleTotp: process.env.REACT_APP_NAMES_HANDLE_TOTP,
  },
  authorities: {
    signUpSignIn: {
      authority:
        process.env.REACT_APP_AUTHORITY_BASE_URL +
        "/" +
        process.env.REACT_APP_NAMES_SIGNUP_SIGNIN,
    },
    changePassword: {
      authority:
        process.env.REACT_APP_AUTHORITY_BASE_URL +
        "/" +
        process.env.REACT_APP_NAMES_CHANGEPASSWORD,
    },
    editProfile: {
      authority:
        process.env.REACT_APP_AUTHORITY_BASE_URL +
        "/" +
        process.env.REACT_APP_NAMES_EDITPROFILE,
    },
    deleteUser: {
      authority:
        process.env.REACT_APP_AUTHORITY_BASE_URL +
        "/" +
        process.env.REACT_APP_NAMES_DELETEUSER,
    },
    handleTotp: {
      authority:
        process.env.REACT_APP_AUTHORITY_BASE_URL +
        "/" +
        process.env.REACT_APP_NAMES_HANDLE_TOTP,
    },
  },
  authorityDomain: process.env.REACT_APP_AUTHORITY_DOMAIN,
};
