import { Link } from "@skf-internal/ui-components-react";
import { useNavigate } from "react-router-dom";

const BackToOverviewLink = () => {
  const navigate = useNavigate();
  return (
    <div className="backToOverView">
    <Link
      className="link"
      as="a"
      feIcon={{
        feIcon: "arrowLeft",
        position: "left",
      }}
      href="#"
      onClick={() => navigate("/")}
    >
      Back
    </Link>
    </div>
  );
};

export default BackToOverviewLink;
