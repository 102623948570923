import HorizontalCard from "./HorizontalCard";

export interface ISettingsCardProps {
     icon: any;
    heading: string;
    content?: any;
    moretext: string;
    morelink: any;
    secondLinkText?: string;
    secondLink?: any;
    user: any;
  }
  
  export const SettingsCard = (props: ISettingsCardProps) => {
    const { icon, heading, content, moretext, morelink , secondLinkText , secondLink, user } =
      props;

      let settings_moretext:string = moretext;
      let settings_morelink = morelink; 
      let settings_seclinktext = secondLinkText; 
      let settings_seclink = secondLink; 

      if (user?.idTokenClaims?.idp === "skf.com"){
        settings_moretext = secondLinkText ?? ""; 
        settings_morelink = secondLink; 
        settings_seclinktext = ""; 
        settings_seclink = null; 
      }



      return   <HorizontalCard
          icon="person"
          heading="My Profile"
          morelink={settings_morelink}
          moretext={settings_moretext}
          secondLinkText={settings_seclinktext}
          secondLink={settings_seclink} />

  }