import { useMsal } from "@azure/msal-react";
import { AccountInfo, EventType } from "@azure/msal-browser";
import {
  Link,
  Divider,
} from "@skf-internal/ui-components-react";
import { useEffect, useState, useRef } from "react";
import LogoutButton from "./logout";

export interface IUserProps {
  auth: any;
}

export default function User(props: IUserProps) {
  const [showUser, setShowUser] = useState(false);

  const handleUserClick = () => {
    setShowUser(!showUser);
  };
  const [user, setUser] = useState<any>();
  const [userName, setUserName] = useState<string>("");
  const { accounts, instance } = useMsal();

  useEffect(() => {
    
    let storageUserStr = sessionStorage.getItem("user"); 
    if (storageUserStr != null){
      let storageUser =  JSON.parse(storageUserStr) as AccountInfo
      setUser(storageUser);
      setUserName(storageUser?.name??""); 
    } else if (accounts.length > 0) {
      setUser(accounts[0]);
      setUserName(accounts[0].name??"");
      sessionStorage.setItem("user", JSON.stringify(accounts[0])); 
    }

    const callbackId = instance.addEventCallback((event:any) => {
      if ( event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.payload.account) {
        setUser(event.payload.account);
        setUserName( event.payload.account.name);
        sessionStorage.setItem("user", JSON.stringify(event.payload.account)); 
      }
    });

    // Clean up the event listener on unmount
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [accounts]);
  return (
    <>
      <span id="userinfo">
        <span className="loggedin-user" title={user?.idTokenClaims?.email}>
          <Link
            as="button"
            feIcon={{
              feIcon: "chevronDown",
              position: "right",
            }}
            feColor="secondary"
            type="button"
            onClick={handleUserClick}
          >
            {userName}
          </Link>
        </span>

        {showUser && (
          <span id="userDropDown">
            <div className="user">
              <span className="name">{userName}</span>
              <span className="email" title={user?.idTokenClaims?.email}>{user?.idTokenClaims?.email}</span>
            </div>
            <Divider feColor="secondary" />
            <LogoutButton />
          </span>
        )}
      </span>
    </>
  );
}
