import  { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { policies } from "../../policies";
import { InteractionStatus } from "@azure/msal-browser";
import BackToOverviewLink from "../components/BackToOverview";
import { fetchAccessToken } from "../common/Common";
import SubHeader from "../components/SubHeader";
import HorizontalCard from "../components/HorizontalCard";

const SecurityInfo = () => {
  const { instance, accounts, inProgress } = useMsal();

  //to be developed
  const language = "en";

  const [user, setUser] = useState<any>();
  const [pwdChanged, setPwdChanged] = useState<any>('Loading ...  ');
  const [totp, setTotp] = useState("");
  useEffect(() => {
    if (accounts.length > 0) {
      setUser(accounts[0]);
      fetchLastPwdData(accounts[0]);
      fetchTOTPData(accounts[0]);
    }
  }, [accounts]);

  const fetchLastPwdData = async (user: any) => {
    try {
      const token = await fetchAccessToken();
      const response = await fetch(
        process.env.REACT_APP_UIC_URL +
          "/api/v1/user/" +
          user?.localAccountId +
          "/pwdinfo",
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Om det krävs
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP fel! Status: ${response.status}`);
      }

      const result = await response.json();
      // setPwdChanged(result?.lastPasswordChangeDateTime);
     
      let date = new Date(result?.lastPasswordChangeDateTime);
   
      let formattedDate = date?.toLocaleString("en-us",{ dateStyle:"medium",timeStyle :"short"});
     setPwdChanged(formattedDate);
    } catch (error) {
      // Hantera fel om något går fel under API-anropet
      //setError(error.message);
    }
  };
  const fetchTOTPData = async (user: any) => {
    try {
      const token = await fetchAccessToken();
      const response = await fetch(
        process.env.REACT_APP_UIC_URL + "/api/v1/user/" + user?.localAccountId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Om det krävs
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP fel! Status: ${response.status}`);
      }

      const result = await response.json();
      if (result && result.length > 0) {
        setTotp("enabled");
      } else {
        setTotp("not enabled");
      }
    } catch (error) {
      // Hantera fel om något går fel under API-anropet
      //setError(error.message);
    }
  };
  useEffect(() => {
    // Kör funktionen för att hämta data när komponenten monteras
  }, []);
  
  const passwordChangeContent = (
    <div className="password-change">
      Last Changed {pwdChanged}
    </div>
  )
  const TOTPcontent = (
    <div className="password-change">
    User Two-factor authentication is  {totp}
  </div>
  )
  const handleChangePswd = () => {
    const pswrdReset: any = policies.authorities.changePassword;

    if (language !== "en") {
      pswrdReset.extraQueryParameters = { ui_locales: language };
    }
    if (inProgress === InteractionStatus.None) {
      instance.acquireTokenRedirect(pswrdReset);
    }
  };
  const handleTOTP = () => {
    const totpHandle: any = policies.authorities.handleTotp;
   
    if (language !== "en") {
      totpHandle.extraQueryParameters = { ui_locales: language };
    }
    if (inProgress === InteractionStatus.None) {
      instance.acquireTokenRedirect(totpHandle);
    }
    // window.open(totpHandle, "_blank");
  };

  return (
    <>
    <SubHeader title={ `Security info`} subContent={"These are the methods you use to sign into your account or reset your password."} />  
    
      <BackToOverviewLink />
   
    <section className="application-card-container">
   { user?.idTokenClaims?.idp == null &&   <HorizontalCard
                icon="lock"
              heading="Change Password"
              morelink={handleChangePswd }
              moretext="Open"
              content={pwdChanged &&  passwordChangeContent}
            /> }   
 
  { false &&   <HorizontalCard
              icon="settings"
              heading="Two-factor authentication"
              morelink={handleTOTP }  
              moretext="Open"
              content={TOTPcontent} 
            />}        
    </section>
   
    </>
  );
};

export default SecurityInfo;
